import React, { useEffect } from 'react';
import {
  TextField,
} from '@mui/material';
import { useMutation } from 'react-query';
import { addNotes } from 'pages/ScalesManifest/services/scales-manifest.services';
import { useHttp } from 'hooks/use-fetch';
import { Dialog } from 'components/Common/Dialog';
import trackMixpanelEvent from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

interface AddNotesDialogProps {
  open: boolean;
  attemptId: string;
  categoryId: string;
  note?: string;
  onClose: (shouldRefetch?: boolean) => void;
}

export function AddNotesDialog({
  open,
  attemptId,
  categoryId,
  note: initialNotes,
  onClose,
}: AddNotesDialogProps): JSX.Element {
  const { http } = useHttp();
  const [note, setNote] = React.useState('');

  useEffect(() => {
    setNote(initialNotes ?? '');
  }, [open, initialNotes]);

  const resetAndClose = (shouldRefetch?: boolean) => {
    setNote('');
    onClose(shouldRefetch);
  };

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => addNotes(http.post, attemptId, categoryId, note),
    onSettled: () => {
      resetAndClose(true);
    },
  });

  const onAdd = async () => {
    trackMixpanelEvent(MixpanelEventName.ADDED_NOTE_ON_SCALE, {
      scale: attemptId,
    });
    mutateAsync();
  };

  return (
    <Dialog
      open={open}
      size='md'
      title={`${initialNotes ? 'Edit' : 'Add'} note`}
      actionBtnLabel={initialNotes ? 'EDIT' : 'ADD'}
      actionBtnDisabled={!note}
      actionBtnLoading={isLoading}
      actionBtnHandler={onAdd}
      onClose={() => resetAndClose()}
      renderContent={() => (
        <TextField
          multiline
          rows={6}
          fullWidth
          variant='outlined'
          placeholder='Enter your note here'
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      )}
    />
  );
}

export default AddNotesDialog;
