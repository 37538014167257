import { useAuth0 } from '@auth0/auth0-react';
import Spinner from 'components/Common/Spinner';
import { useHttp } from 'hooks/use-fetch';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { Logger } from 'utils/logger';
import { auth, mainNav } from 'utils/spaUrls';

export default function AdminRoute({ outlet }: { outlet: JSX.Element }) {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const { providerId } = useHttp();
  const admins = process.env.REACT_APP_ADMIN_EMAIL?.split(',') || [];
  if (!admins) {
    Logger.error('Missing admin emails');
  }

  if (isLoading) {
    return <Spinner />;
  }
  else if (isAuthenticated && !providerId) {
    return <Spinner />;
  }
  else if (isAuthenticated && admins.includes(user?.email || '')) {
    return outlet;
  }
  else if (isAuthenticated) {
    return <Navigate to={mainNav.dashboard} />;
  }
  else {
    return <Navigate to={auth.login} />;
  }
}
