import { useState } from 'react';
import { fetchPatientList } from 'pages/Dashboard/services/patient.services';
import { useQuery } from 'react-query';
import { patientUrls, whoIam } from 'utils/apiUrls';
import { useHttp } from 'hooks/use-fetch';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { fetchWhoIamCategories } from 'pages/Dashboard/services/whoiam.services';
import { capitalize } from '@mui/material';
import { CategoryType } from 'pages/Dashboard/types/whoiam.types';

type PatientNames = {
  [key: string]: {
    name: string;
    id: string;
    dob: string;
  };
};
type ProviderNames = {
  [key: string]: {
    id: string;
    name: string;
  };
};

export default function usePatientProviderMap(queryKey?: string) {
  const { http, providerId } = useHttp();

  const [patientNames, setPatientNames] = useState<PatientNames>({} as PatientNames);
  const [providerNames, setProviderNames] = useState<ProviderNames>({} as ProviderNames);
  const [scaleNames, setScaleNames] = useState<Record<string, string>>(
    {} as Record<string, string>,
  );

  const firstPatientId = Object.keys(patientNames)?.[0];

  const {
    isFetching: isFetchingPatients,
    isError: isPatientsError,
    refetch: refetchPatients,
  } = useQuery([patientUrls.listPatients.queryUrl, providerId, PATIENT_LIST_VIEW.READY, queryKey], {
    queryFn: fetchPatientList(http.get),
    enabled: !!providerId,
    refetchOnMount: 'always',
    onSuccess: ({ patients }) => {
      const patientNamesMap = {} as PatientNames;
      const providerNamesMap = {} as ProviderNames;
      patients.forEach((patient) => {
        const {
          id = '',
          name,
          dateOfBirth,
          provider = { id: '', firstName: '', lastName: '' },
        } = patient || {};
        patientNamesMap[id] = {
          name: `${capitalize(name?.firstName ?? '')} ${capitalize(name?.lastName ?? '')}`,
          id,
          dob: `${dateOfBirth?.day}/${dateOfBirth?.month}/${dateOfBirth?.year}`,
        };
        providerNamesMap[provider.id] = {
          id: provider.id,
          name: `${provider.firstName} ${provider.lastName}`,
        };
      });
      setPatientNames(patientNamesMap);
      setProviderNames(providerNamesMap);
    },
  });

  const {
    isFetching: isFetchingCategories,
    isError: isCategoriesError,
    refetch: refetchCategories,
  } = useQuery([whoIam.categories.queryUrl, providerId, firstPatientId], {
    queryFn: fetchWhoIamCategories(http.get),
    onSuccess: ({ categories }) => {
      const scales = categories.reduce((acc, category) => {
        category.type === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE
          && (acc[category.id] = category.abbreviation);
        return acc;
      }, {} as Record<string, string>);
      setScaleNames(scales);
    },
    enabled: !!providerId && !!firstPatientId,
  });

  return {
    isFetching: isFetchingCategories || isFetchingPatients,
    isError: isCategoriesError || isPatientsError,
    patientNames,
    providerNames,
    scaleNames,
    refetchPatients,
    refetchCategories,
  };
}
