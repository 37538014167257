import * as React from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { SideNavCtx } from 'components/Layouts/constants';
import Typography from '@mui/material/Typography';
import NavTree, { reportsNav } from 'utils/NavTree';
import { useHttp } from 'hooks/use-fetch';
import { NavTreeIds } from 'types/NavTreeModel';

export default function SideNav() {
  const theme = useTheme();
  const { drawerWidth, isFullNav } = React.useContext(SideNavCtx);
  const { isBillingAdmin } = useHttp();

  const updatedNavTree = React.useMemo(() => {
    const navTree = NavTree.filter((navItem) => navItem.id !== NavTreeIds.ADD_PATIENT);
    return isBillingAdmin ? [...navTree, reportsNav] : NavTree;
  }, [isBillingAdmin]);

  return (
    <Drawer
      open
      sx={{
        width: drawerWidth,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          marginTop: theme.custom.appBarHeight,
          zIndex: theme.zIndex.appBar - 1,
        },
      }}
      variant='permanent'
    >
      <List disablePadding>
        {updatedNavTree.map((navItem) => {
          const Icon = navItem.icon;
          return (
            <Tooltip title={navItem.name} key={navItem.name}>
              <ListItemButton component={Link} to={navItem.spaUrl} divider>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                {isFullNav ? <Typography>{navItem.name}</Typography> : null}
              </ListItemButton>
            </Tooltip>
          );
        })}
      </List>
    </Drawer>
  );
}
