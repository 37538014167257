import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueries, useQuery } from 'react-query';
import { Box, Fade, Menu, MenuItem, Typography, capitalize } from '@mui/material';
import Grid from '@mui/material/Grid';
import GetAppIcon from '@mui/icons-material/GetApp';
import PageHeader from 'components/Common/PageHeader';
import Spinner from 'components/Common/Spinner';
import TabComponent, { TabContext } from 'components/Common/TabComponent';
import Toast, { ToastProps, ToastType } from 'components/Common/Toast';
import { useHttp } from 'hooks/use-fetch';
import { PatientCtx } from 'pages/Dashboard/constants';
import { Logger } from 'utils/logger';
import {
  allergiesUrls,
  backgroundsUrls,
  diagnosisUrls,
  familyHistoryUrls,
  hospitalizationsUrls,
  labsUrls,
  lifestyleUrls,
  medicationsUrls,
  patientProviderUrls,
  patientUrls,
  providers,
  symptomsUrls,
  trackingUrls,
  whoIam,
} from 'utils/apiUrls';
import { downloadUrl } from 'utils/service';
import {
  fetchPatientExportData,
  fetchWhoIamCategories,
} from 'pages/Dashboard/services/whoiam.services';
import { fetchProvidersAssociationsList } from 'pages/Dashboard/services/provider.services';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { Mood } from 'pages/Dashboard/types/moods.types';
import {
  fetchCurrentDiagnosisList,
  fetchDiagnosisList,
} from 'pages/Dashboard/services/diagnosis.services';
import {
  fetchCurrentMedicationList,
  fetchMedicationList,
} from 'pages/Dashboard/services/medications.services';
import { fetchPatientDetails } from 'pages/Dashboard/services/patient.services';
import fetchFamilyHistoryList from 'pages/Dashboard/services/familyhistory.services';
import {
  fetchCurrentSymptomList,
  fetchSymptomList,
} from 'pages/Dashboard/services/symptoms.services';
import fetchAllergyList from 'pages/Dashboard/services/allergies.services';
import fetchHospitalizationList from 'pages/Dashboard/services/hospitalizations.services';
import { fetchLabList } from 'pages/Dashboard/services/labs.services';
import { fetchLifeStyleList } from 'pages/Dashboard/services/lifestyle.services';
import fetchBackgroundList from 'pages/Dashboard/services/backgrounds.services';
import {
  fetchPatientProvidersList,
  fetchPatientPrimaryCareProviderList,
  fetchPatientMentalHealthProviderList,
  fetchPatientCurrentPrimaryCareProviderList,
  fetchPatientCurrentMentalHealthProviderList,
  fetchPatientCurrentProvidersList,
} from 'pages/Dashboard/services/patientprovider.services';
import {
  defaultMoodTracking,
  defaultWaypointTracking,
  TimeFrameOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import PatientTracking from 'pages/Dashboard/PatientTracking';
import PatientProfile from 'pages/Dashboard/PatientProfile';
import CurrentRegimen from 'pages/Dashboard/CurrentRegimen';
import HealthRecord from 'pages/Dashboard/HealthRecord';
import {
  fetchMoodAverage,
  fetchMoodChart,
  fetchWaypointTracking,
} from 'pages/Dashboard/services/tracking.services';
import { KeyboardArrowDown } from '@mui/icons-material';
import LoadingButton from 'components/Common/LoadingButton';
import trackMixpanelEvent from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

export default function PatientDetail() {
  const { id } = useParams();
  const { http, providerId, superAdminId } = useHttp();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [toastProps, setToastProps] = React.useState<ToastProps | null>(null);
  const [selectTimeFrame, setSelectTimeFrame] = React.useState(
    TimeFrameOptions.LAST_30_DAYS as string,
  );

  const open = Boolean(anchorEl);
  const handleClick = (event?: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event?.currentTarget ?? null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setToastProps(null);
  };

  const onChangeTimeFrame = (timeFrameValue: string) => {
    setSelectTimeFrame(timeFrameValue);
  };

  const onError = (message: string) => {
    Logger.error(message);
    setToastProps({
      message,
      open: true,
      type: ToastType.error,
    });
  };

  const [
    { data: patientDetails, isFetching: patientDetailsFetching },
    diagnosis,
    currentDiagnosis,
    medications,
    currentMedications,
    familyHistory,
    symptoms,
    currentSymptoms,
    allergies,
    hospitalizations,
    labs,
    patientProviders,
    lifestyle,
    backgrounds,
    patientPrimaryCareProviders,
    patientMentalHealthProviders,
    patientCurrentPrimaryCareProviders,
    patientCurrentMentalHealthProviders,
    patientCurrentProviders,
  ] = useQueries([
    {
      queryKey: [patientUrls.patientDetails.queryUrl, providerId, id],
      queryFn: fetchPatientDetails(http.get),
      onError: () => onError('Error fetching patient details'),
    },
    {
      queryKey: [diagnosisUrls.listDiagnosis.queryUrl, providerId, id],
      queryFn: fetchDiagnosisList(http.get),
      onError: () => onError('Error fetching patient diagnosis'),
    },
    {
      queryKey: [diagnosisUrls.listDiagnosis.queryUrl, providerId, id, 'current'],
      queryFn: fetchCurrentDiagnosisList(http.get),
      onError: () => onError('Error fetching patient current diagnosis'),
    },
    {
      queryKey: [medicationsUrls.listMedications.queryUrl, providerId, id],
      queryFn: fetchMedicationList(http.get),
      onError: () => onError('Error fetching patient medications'),
    },
    {
      queryKey: [medicationsUrls.listMedications.queryUrl, providerId, id, 'current'],
      queryFn: fetchCurrentMedicationList(http.get),
      onError: () => onError('Error fetching patient current medications'),
    },
    {
      queryKey: [familyHistoryUrls.listFamilyHistory.queryUrl, providerId, id],
      queryFn: fetchFamilyHistoryList(http.get),
      onError: () => onError('Error fetching patient family history'),
    },
    {
      queryKey: [symptomsUrls.listSymptoms.queryUrl, providerId, id],
      queryFn: fetchSymptomList(http.get),
      onError: () => onError('Error fetching patient symptoms'),
    },
    {
      queryKey: [symptomsUrls.listSymptoms.queryUrl, providerId, id, 'current'],
      queryFn: fetchCurrentSymptomList(http.get),
      onError: () => onError('Error fetching patient current symptoms'),
    },
    {
      queryKey: [allergiesUrls.listAllergies.queryUrl, providerId, id],
      queryFn: fetchAllergyList(http.get),
      onError: () => onError('Error fetching patient allergies'),
    },
    {
      queryKey: [hospitalizationsUrls.listHospitalizations.queryUrl, providerId, id],
      queryFn: fetchHospitalizationList(http.get),
      onError: () => onError('Error fetching patient hospitalizations'),
    },
    {
      queryKey: [labsUrls.listLabs.queryUrl, providerId, id],
      queryFn: fetchLabList(http.get),
      onError: () => onError('Error fetching patient labs'),
    },
    {
      queryKey: [patientProviderUrls.listPatientProviders.queryUrl, providerId, id],
      queryFn: fetchPatientProvidersList(http.get),
      onError: () => onError('Error fetching patient providers'),
    },
    {
      queryKey: [lifestyleUrls.listLifestyle.queryUrl, providerId, id],
      queryFn: fetchLifeStyleList(http.get),
      onError: () => onError('Error fetching patient lifestyle'),
    },
    {
      queryKey: [backgroundsUrls.listBackgrounds.queryUrl, providerId, id],
      queryFn: fetchBackgroundList(http.get),
      onError: () => onError('Error fetching patient backgrounds'),
    },
    {
      queryKey: [patientProviderUrls.listPatientPrimaryCareProviders.queryUrl, providerId, id],
      queryFn: fetchPatientPrimaryCareProviderList(http.get),
      onError: () => onError('Error fetching patient primary care providers'),
    },
    {
      queryKey: [patientProviderUrls.listPatientMentalHealthProviders.queryUrl, providerId, id],
      queryFn: fetchPatientMentalHealthProviderList(http.get),
      onError: () => onError('Error fetching patient mental health providers'),
    },
    {
      queryKey: [
        patientProviderUrls.listPatientPrimaryCareProviders.queryUrl,
        providerId,
        id,
        'current',
      ],
      queryFn: fetchPatientCurrentPrimaryCareProviderList(http.get),
      onError: () => onError('Error fetching patient current primary care providers'),
    },
    {
      queryKey: [
        patientProviderUrls.listPatientMentalHealthProviders.queryUrl,
        providerId,
        id,
        'current',
      ],
      queryFn: fetchPatientCurrentMentalHealthProviderList(http.get),
      onError: () => onError('Error fetching patient current mental health providers'),
    },
    {
      queryKey: [patientProviderUrls.listPatientProviders.queryUrl, providerId, id, 'current'],
      queryFn: fetchPatientCurrentProvidersList(http.get),
      onError: () => onError('Error fetching patient current providers'),
    },
  ]);

  const currentMoodAverage = useQuery(
    [`${trackingUrls.moodAverage.queryUrl}`, providerId, id, selectTimeFrame, 'current'],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
    },
  );

  const previousMoodAverage = useQuery(
    [`${trackingUrls.moodAverage.queryUrl}`, providerId, id, selectTimeFrame, 'previous'],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
    },
  );

  const whoIAmCategories = useQuery([whoIam.categories.queryUrl, providerId, id], {
    queryFn: fetchWhoIamCategories(http.get),
  });

  const currentWaypoint = useQuery(
    [`${trackingUrls.waypoint.queryUrl}`, providerId, id, selectTimeFrame, 'current'],
    {
      queryFn: fetchWaypointTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
    },
  );

  const previousWaypoint = useQuery(
    [`${trackingUrls.waypoint.queryUrl}`, providerId, id, selectTimeFrame, 'previous'],
    {
      queryFn: fetchWaypointTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
    },
  );

  const moodChartHappiness = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      Mood.MOOD_HAPPINESS,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
    },
  );

  const moodChartAnxiousness = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      Mood.MOOD_ANXIOUSNESS,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
    },
  );

  const moodChartMotivation = useQuery(
    [
      `${trackingUrls.moodList.queryUrl}`,
      providerId,
      id,
      selectTimeFrame,
      Mood.MOOD_MOTIVATION,
      'charts',
    ],
    {
      queryFn: fetchMoodChart(http.get),
      onError: () => onError('Error fetching patient mood'),
    },
  );

  const { mutateAsync: fetchExportData, isLoading: exportLoading } = useMutation(
    (asPDF?: boolean) => fetchPatientExportData(http.post, providerId, id ?? '', asPDF),
    {
      onSuccess: (dataBlob) => {
        const fileName = `${patientName} Report`;
        const blob = new Blob([dataBlob], {
          type: dataBlob.type === 'application/pdf' ? dataBlob.type : 'application/vnd.ms-excel',
        });
        const url = window.URL.createObjectURL(blob);
        downloadUrl(url, fileName);
      },
    },
  );

  const providerAssociations = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'patient-detail'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
    },
  );

  // eslint-disable-next-line max-len
  const isMoodLoading = moodChartAnxiousness.isLoading || moodChartMotivation.isLoading || moodChartHappiness.isLoading;

  const isTrackingLoading = currentMoodAverage.isLoading
    || previousMoodAverage.isLoading
    || currentWaypoint.isLoading
    || previousWaypoint.isLoading
    || isMoodLoading;

  const isPatientProfileLoading = whoIAmCategories.isLoading;

  const defaultCategories = {
    categories: [],
  };

  const patientName = React.useMemo(
    () => {
      const firstName = capitalize(patientDetails?.patient?.name?.firstName ?? '');
      const lastName = capitalize(patientDetails?.patient?.name?.lastName ?? '');
      return `${firstName} ${lastName}`;
    },
    [patientDetails?.patient?.name?.firstName, patientDetails?.patient?.name?.lastName],
  );

  const providerName = React.useMemo(
    () => {
      const firstName = capitalize(patientDetails?.patient?.provider?.firstName ?? '');
      const lastName = capitalize(patientDetails?.patient?.provider?.lastName ?? '');
      return `${firstName} ${lastName}`;
    },
    [patientDetails?.patient?.provider?.firstName, patientDetails?.patient?.provider?.lastName],
  );

  if (!patientDetails || patientDetailsFetching) {
    return <Spinner />;
  }

  const configureTabs: TabContext[] = [
    {
      header: 'Medical Regimen & History',
      content: (
        <Grid pt={7} container spacing={7}>
          <Grid item xs={12}>
            <CurrentRegimen
              currentDiagnosis={currentDiagnosis}
              currentMedications={currentMedications}
              currentProviders={patientCurrentProviders}
              currentPrimaryCareProviders={patientCurrentPrimaryCareProviders}
              currentMentalHealthProviders={patientCurrentMentalHealthProviders}
            />
          </Grid>
          <Grid item xs={12}>
            <HealthRecord
              diagnosis={diagnosis}
              medications={medications}
              familyHistory={familyHistory}
              symptoms={symptoms}
              currentSymptoms={currentSymptoms}
              allergies={allergies}
              hospitalizations={hospitalizations}
              providers={patientProviders}
              primaryCareProviders={patientPrimaryCareProviders}
              mentalHealthProviders={patientMentalHealthProviders}
              lifeStyle={lifestyle}
              backgrounds={backgrounds}
              labs={labs}
            />
          </Grid>
        </Grid>
      ),
    },
    {
      header: 'Mood & Waypoints',
      content: (
        <Grid pt={7} container spacing={7}>
          <Grid item xs={12}>
            {isTrackingLoading ? (
              <Spinner />
            ) : (
              <PatientTracking
                timeFrame={selectTimeFrame}
                onChangeTimeFrame={onChangeTimeFrame}
                currentMoodData={
                  currentMoodAverage?.data?.moods ? currentMoodAverage?.data : defaultMoodTracking
                }
                previousMoodData={
                  previousMoodAverage?.data?.moods ? previousMoodAverage?.data : defaultMoodTracking
                }
                currentWaypointData={
                  currentWaypoint?.data?.waypoints ? currentWaypoint.data : defaultWaypointTracking
                }
                previousWaypointData={
                  previousWaypoint?.data?.waypoints
                    ? previousWaypoint?.data
                    : defaultWaypointTracking
                }
                moodChartHappiness={moodChartHappiness?.data || defaultMoodTracking}
                moodChartAnxiousness={moodChartAnxiousness?.data || defaultMoodTracking}
                moodChartMotivation={moodChartMotivation?.data || defaultMoodTracking}
              />
            )}
          </Grid>
        </Grid>
      ),
    },
    {
      header: 'Patient Profile',
      content: (
        <Grid mt={2} container spacing={2}>
          <Grid item xs={12}>
            {isPatientProfileLoading ? (
              <Spinner />
            ) : (
              <PatientProfile
                categoriesData={whoIAmCategories?.data || defaultCategories}
                backgrounds={backgrounds}
              />
            )}
          </Grid>
        </Grid>
      ),
    },
  ];

  const exportData = (asPDF: boolean) => {
    handleMenuClose();
    fetchExportData(asPDF);
    trackMixpanelEvent(MixpanelEventName.EXPORT_DATA_CLICKED, {
      'patient id': id ?? '',
      'as pdf': `${asPDF}`,
    });
  };

  return (
    <PatientCtx.Provider value={patientDetails.patient as PatientObj}>
      {!!providerAssociations.data?.providers.length && (
        <Box>
          <Typography variant='subtitle1'>{providerName}</Typography>
        </Box>
      )}
      <PageHeader
        title={patientName}
        subtitle={googleDateToDateString(patientDetails?.patient?.dateOfBirth as GoogleDate)}
      />
      <Box sx={{ m: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        <LoadingButton
          variant='outlined'
          onClick={handleClick}
          endIcon={<GetAppIcon />}
          startIcon={<KeyboardArrowDown />}
          isLoading={exportLoading}
          disabled={!!superAdminId}
          label='EXPORT DATA'
        />
        <Menu
          id='fade-menu'
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl as HTMLElement}
          open={open}
          onClose={handleMenuClose}
          TransitionComponent={Fade}
          sx={{ '& .MuiMenu-paper': { width: 152 } }}
        >
          <MenuItem onClick={() => exportData(false)}>As Excel</MenuItem>
          <MenuItem onClick={() => exportData(true)}>As PDF</MenuItem>
        </Menu>
      </Box>
      <TabComponent variant='fullWidth' tabContext={configureTabs} />
      {toastProps ? <Toast {...toastProps} onClose={handleClose} /> : null}
    </PatientCtx.Provider>
  );
}
