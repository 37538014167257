
import { TAttemptDetails, TScalesResponse, TSuggestion } from 'pages/ScalesManifest/types/scales-manifest.types';
import { QueryFunctionContext } from 'react-query';
import { scalesManifestUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const fetchScaleResults = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<TScalesResponse> {
  const [, categoryId,
    providerId,
    patientId,
    startDate,
    endData,
    attemptId,
    page,
    size] = queryKey;

  return method(scalesManifestUrls.listScalesManifest.apiUrl(
    categoryId as string,
    providerId as string,
    patientId as string,
    startDate as string,
    endData as string,
    attemptId as string,
    page as number,
    size as number,
  ).list);
};

export const fetchAttemptDetails = (method: HttpType['get'], attemptId: string, categoryId: string): Promise<TAttemptDetails> => method(scalesManifestUrls.attemptDetails.apiUrl(
    categoryId as string,
    attemptId as string,
).details);

export const addNotes = (method: HttpType['post'], attemptId: string, categoryId: string, notes: string): Promise<void> => method(scalesManifestUrls.addNotes.apiUrl(
    categoryId as string,
    attemptId as string,
).add, { data: { notes } });

export const sendScaleSuggestions = (method: HttpType['post'], patientId: string, providerId: string, suggestions: TSuggestion[]): Promise<void> => method(scalesManifestUrls.sendScaleSuggestions.apiUrl(
    patientId as string,
).send, { data: { patientId, providerId, suggestions } });
