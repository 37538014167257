import Home from '@mui/icons-material/Home';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Summarize from '@mui/icons-material/Summarize';
import { NavItemModel, NavTreeIds, NavTreeModel } from 'types/NavTreeModel';
import * as spaUrl from 'utils/spaUrls';

export const reportsNav: NavItemModel = {
  name: 'Reports',
  icon: Summarize,
  spaUrl: spaUrl.mainNav.reports,
  id: NavTreeIds.REPORTS,
};

const NavTree: NavTreeModel = [
  {
    name: 'Home',
    icon: Home,
    spaUrl: spaUrl.mainNav.dashboard,
    id: NavTreeIds.HOME,
  },
  {
    name: 'Add a patient',
    icon: PersonAddIcon,
    spaUrl: spaUrl.mainNav.addNewPatient,
    id: NavTreeIds.ADD_PATIENT,
  },
];

export default NavTree;
