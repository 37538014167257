import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

export enum NavTreeIds {
  HOME = 'home',
  ADD_PATIENT = 'add-patient',
  REPORTS = 'reports',
}

export type NavItemModel = {
  name: string;
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string; };
  spaUrl: string;
  id: NavTreeIds;
}

export type NavTreeModel = NavItemModel[];
