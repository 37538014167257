import React from 'react';
import { Box, TextField } from '@mui/material';
import Autocomplete, { Option } from 'pages/ScalesManifest/components/Autocomplete';
import { format } from 'date-fns';
import trackMixpanelEvent from 'utils/utilMethods';
import { MixpanelEventName } from 'utils/constants';

export type TFilters = {
  providerName: Option;
  patientName: Option;
  scale: Option;
  startDate: Date | '';
  endDate: Date | '';
};

interface IFiltersProps {
  filters: TFilters;
  setFilters: React.Dispatch<React.SetStateAction<TFilters>>;
  providerNameOptions: Option[];
  patientNameOptions: Option[];
  scaleOptions: Option[];
  isFetching: boolean;
}

function Filters({
  filters,
  setFilters,
  providerNameOptions,
  patientNameOptions,
  scaleOptions,
  isFetching,
}: IFiltersProps) {
  const { providerName, patientName, scale, startDate, endDate } = filters;

  const setFilterChange = (key: keyof TFilters, value: Option | Date | string) => {
    trackMixpanelEvent(MixpanelEventName.FILTERS_APPLIED, {
      filter: key,
    });
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const setFiltersValue = (key: keyof TFilters) => (value: Option) => {
    setFilterChange(key, value);
  };

  const convertToLocalDate = (dateString?: string): Date => {
    const dateParts = dateString ? dateString.split('-') : new Date().toISOString().split('T')[0].split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);
    return new Date(year, month, day);
  };

  const setDateFiltersValue = (key: keyof TFilters) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => setFilterChange(key, event.target.value ? convertToLocalDate(event.target.value) : '');

  const formatDate = (date: Date | '') => (date ? format(date, 'yyyy-MM-dd') : '');
  const formattedStartDate = React.useMemo(() => formatDate(startDate), [startDate]);
  const formattedEndDate = React.useMemo(() => formatDate(endDate), [endDate]);
  const formattedTodaysDate = React.useMemo(
    () => formatDate(convertToLocalDate()),
    [],
  );

  return (
    <Box display='flex' flexDirection='row' alignItems='flex-start' paddingY={2} gap={2}>
      <Autocomplete
        value={providerName}
        label='Provider name'
        setValue={setFiltersValue('providerName')}
        options={providerNameOptions}
        loading={isFetching}
        disabled={isFetching}
        testId='provider-name-autocomplete'
      />
      <Autocomplete
        value={patientName}
        label='Patient name'
        setValue={setFiltersValue('patientName')}
        options={patientNameOptions}
        loading={isFetching}
        disabled={isFetching}
      />
      <Autocomplete
        value={scale}
        label='Scale'
        setValue={setFiltersValue('scale')}
        options={scaleOptions}
        loading={isFetching}
        disabled={isFetching}
      />
      <TextField
        label='Start Date'
        placeholder='Start Date'
        type='date'
        size='small'
        InputLabelProps={{ shrink: true }}
        value={formattedStartDate}
        onChange={setDateFiltersValue('startDate')}
        inputProps={{ max: formattedTodaysDate }}
        fullWidth
        disabled={isFetching}
      />
      <TextField
        label='End Date'
        type='date'
        size='small'
        InputLabelProps={{ shrink: true }}
        value={formattedEndDate}
        onChange={setDateFiltersValue('endDate')}
        inputProps={{ min: formattedStartDate || undefined, max: formattedTodaysDate }}
        fullWidth
        disabled={isFetching}
      />
    </Box>
  );
}

export default Filters;
