import { AlertColor, AlertProps, IconButton, SnackbarContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar, { SnackbarProps } from '@mui/material/Snackbar';
import * as React from 'react';

export const ToastType: { [key in AlertColor]: AlertColor } = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export type ToastProps = SnackbarProps & {
  message: string;
  type: AlertProps['severity'];
  onClose?: (event: React.SyntheticEvent | Event, reason?: string) => void;
};

export default function Toast({ message, type, onClose, ...rest }: ToastProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={type === 'success' ? 1500 : 5000}
      key={`${message}-${type}`}
      sx={{ width: '40%' }}
      open={rest.open}
      onClose={onClose}
      {...rest}
    >
      <SnackbarContent
        action={[
          <IconButton aria-label='close' color='inherit' key='close' onClick={onClose}>
            <CloseIcon />
          </IconButton>,
        ]}
        message={message}
        sx={{ color: `${type === 'success' ? 'primary' : 'error.main'}` }}
      />
    </Snackbar>
  );
}
