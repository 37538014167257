import PrivateRoute from 'components/Common/PrivateRoute';
import Borders from 'components/Layouts/Borders';
import AuthRouter from 'pages/Authentication/Router';
import PatientDetail from 'pages/Dashboard/PatientDetail';
import PageNotFound from 'pages/Exceptions/PageNotFound';
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import * as spaUrls from 'utils/spaUrls';
import AddPatient from 'pages/Dashboard/AddPatient';
import AdminRoute from 'components/Common/AdminRoute';
import ProviderRequestList from 'pages/Admin/ProviderRequestList';
import AddNewPatient from 'pages/Dashboard/AddNewPatient';
import Dashboard from 'pages/Dashboard/Dashboard';
import SuperAdminRoute from 'components/Common/SuperAdminRoute';
import ProvidersList from 'pages/SuperAdmin/ProvidersList';
import ScalesManifest from 'pages/ScalesManifest/ScalesManifest';
import BillingAdminRoute from 'components/Common/BillingAdminRoute';

export default function Router() {
  return (
    <Borders>
      <Routes>
        <Route path='/auth/*' element={<AuthRouter />} />
        <Route path='/' element={<Navigate to={spaUrls.landing} replace />} />
        <Route
          path={spaUrls.mainNav.dashboard}
          element={<PrivateRoute outlet={<Dashboard />} />}
        />
        <Route
          path={spaUrls.mainNav.patientDetail()}
          element={<PrivateRoute outlet={<PatientDetail />} />}
        />
        <Route
          path={spaUrls.mainNav.addPatient}
          element={<BillingAdminRoute outlet={<AddPatient />} showRoute={false} />}
        />
        <Route
          path={spaUrls.mainNav.addNewPatient}
          element={<BillingAdminRoute outlet={<AddNewPatient />} showRoute={false} />}
        />
        <Route
          path={spaUrls.mainNav.reports}
          element={<BillingAdminRoute outlet={<ScalesManifest />} />}
        />
        <Route
          path={spaUrls.adminRoutes.providerRequests}
          element={<AdminRoute outlet={<ProviderRequestList />} />}
        />
        <Route
          path={spaUrls.superAdminRoutes.providersPracticeList}
          element={<SuperAdminRoute outlet={<ProvidersList />} />}
        />
        <Route path='/404' element={<PageNotFound />} />
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Routes>
    </Borders>
  );
}
