import { useState } from 'react';
import { useQuery } from 'react-query';
import { scalesManifestUrls } from 'utils/apiUrls';
import { useHttp } from 'hooks/use-fetch';
import { fetchScaleResults } from 'pages/ScalesManifest/services/scales-manifest.services';
import { TableData } from 'pages/ScalesManifest/types/scales-manifest.types';

export default function useScales({
  categoryId,
  providerId,
  patientId,
  startDate,
  endData,
  attemptId,
  page,
  size,
}: {
  categoryId: string;
  providerId: string;
  patientId: string;
  startDate: string;
  endData: string;
  attemptId: string;
  page: number;
  size: number;
}) {
  const { http } = useHttp();

  const [scales, setScales] = useState<TableData[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const { isFetching, isError, refetch } = useQuery(
    [
      scalesManifestUrls.listScalesManifest.queryUrl,
      categoryId,
      providerId,
      patientId,
      startDate,
      endData,
      attemptId,
      page,
      size,
    ],
    {
      queryFn: fetchScaleResults(http.get),
      refetchOnMount: 'always',
      retry: false,
      onSuccess: ({ results, pagination }) => {
        setScales(results);
        setTotalCount(parseInt(pagination.total, 10));
      },
    },
  );

  return {
    totalCount,
    isFetching,
    isError,
    scales,
    refetch,
  };
}
