import * as React from 'react';
import { Box, Grid, Link, Skeleton, TableCell, Typography, capitalize } from '@mui/material';
import { format } from 'date-fns';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { whoIam } from 'utils/apiUrls';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { CategoryResult } from 'pages/Dashboard/types/whoiam.types';
import { MixpanelEventName, NotSpecified } from 'utils/constants';
import PatientScaleDetailsDialog from 'pages/ScalesManifest/PatientScaleDetailsDialog';
import { TableData } from 'pages/ScalesManifest/types/scales-manifest.types';
import { PatientCtx } from 'pages/Dashboard/constants';
import { GoogleDate, googleDateToDateString } from 'utils/dateUtils';
import theme from 'theme';
import trackMixpanelEvent from 'utils/utilMethods';

const comparison = (result: CategoryResult[], reversed?: boolean) => {
  const diff = result[0].score - result[1].score;
  const SuccessIcon = reversed ? ArrowDropUpIcon : ArrowDropDownIcon;
  const ErrorIcon = reversed ? ArrowDropDownIcon : ArrowDropUpIcon;

  if (reversed ? diff <= 0 : diff >= 0) {
    return (
      <Box display='flex'>
        <ErrorIcon color='error' />
        <Typography color='red'>
          {reversed ? '' : '+'}
          {diff}
        </Typography>
      </Box>
    );
  }
  else {
    return (
      <Box display='flex'>
        <SuccessIcon color='success' />
        <Typography color='green'>
          {reversed ? '+' : ''}
          {diff}
        </Typography>
      </Box>
    );
  }
};

function ScaleTitle({
  scaleName,
  isLink,
  onScaleClickHandler,
}: {
  scaleName: string;
  isLink: boolean;
  onScaleClickHandler: (scale: string) => () => void;
}) {
  return isLink ? (
    <Link sx={{ cursor: 'pointer ' }} onClick={onScaleClickHandler(scaleName)} underline='hover'>
      {scaleName}
    </Link>
  ) : (
    <Typography>{scaleName}</Typography>
  );
}

interface Scale extends CategoryResult {
  categoryId: string;
  scale: string;
}

interface PatientRatingScalesProps {
  phq9Id: string;
  gad7Id: string;
  who5Id: string;
}

export default function PatientRatingScales({
  phq9Id,
  gad7Id,
  who5Id,
}: PatientRatingScalesProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patientId } = useParams();

  const patientCtx = React.useContext(PatientCtx);

  const [selectedScale, setSelectedScale] = React.useState({} as Scale);

  const phq9Scale = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${phq9Id}`, providerId, patientId, phq9Id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
      enabled: !!phq9Id,
    },
  );

  const gad7Scale = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${gad7Id}`, providerId, patientId, gad7Id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
      enabled: !!gad7Id,
    },
  );

  const who5Scale = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${who5Id}`, providerId, patientId, who5Id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
      enabled: !!who5Id,
    },
  );

  if (phq9Scale.isFetching || gad7Scale.isFetching || who5Scale.isFetching) {
    return <TableCell><Skeleton /></TableCell>;
  }

  const phq9ScaleResult = phq9Scale?.data?.results;
  const gad7ScaleResult = gad7Scale?.data?.results;
  const who5ScaleResult = who5Scale?.data?.results;

  const onScaleClickHandler = (scale: string) => () => {
    const selectedScaleDetails = {
      categoryId: who5Id,
      results: who5ScaleResult,
    };

    if (scale === 'PHQ-8') {
      selectedScaleDetails.results = phq9ScaleResult;
      selectedScaleDetails.categoryId = phq9Id;
    }
    else if (scale === 'GAD-7') {
      selectedScaleDetails.results = gad7ScaleResult;
      selectedScaleDetails.categoryId = gad7Id;
    }

    setSelectedScale({
      ...(selectedScaleDetails.results?.[0] || ({} as CategoryResult)),
      categoryId: selectedScaleDetails.categoryId,
      scale,
    });

    trackMixpanelEvent(MixpanelEventName.SCALE_CLICKED, {
      scale,
    });
  };

  return (
    <TableCell>
      <Grid container spacing={2}>
        {phq9Id && (
        <Grid item xs={4}>
          <Box display='flex' flexDirection='column'>
            <ScaleTitle
              scaleName='PHQ-8'
              isLink={!!phq9ScaleResult?.length}
              onScaleClickHandler={onScaleClickHandler}
            />
            {phq9ScaleResult?.length ? (
              <>
                <Box display='flex'>
                  <Typography>{phq9ScaleResult?.[0]?.score}</Typography>
                  {phq9ScaleResult && phq9ScaleResult.length > 1 && comparison(phq9ScaleResult)}
                </Box>
                <Typography>{phq9ScaleResult?.[0]?.description}</Typography>
                <Typography color={theme.custom.colors.lightTextSecondary}>
                  {`(${format(new Date(phq9ScaleResult?.[0]?.attemptedAt || ''), 'MM/dd/yyyy')})`}
                </Typography>
              </>
            ) : (
              <Typography>{NotSpecified}</Typography>
            )}
          </Box>
        </Grid>
        )}
        {gad7Id && (
        <Grid item xs={4}>
          <Box display='flex' flexDirection='column'>
            <ScaleTitle
              scaleName='GAD-7'
              isLink={!!gad7ScaleResult?.length}
              onScaleClickHandler={onScaleClickHandler}
            />
            {gad7ScaleResult?.length ? (
              <>
                <Box display='flex'>
                  <Typography>{gad7ScaleResult?.[0]?.score}</Typography>
                  {gad7ScaleResult && gad7ScaleResult.length > 1 && comparison(gad7ScaleResult)}
                </Box>
                <Typography>{gad7ScaleResult?.[0]?.description}</Typography>

                <Typography color={theme.custom.colors.lightTextSecondary}>
                  {`(${format(new Date(gad7ScaleResult?.[0]?.attemptedAt || ''), 'MM/dd/yyyy')})`}
                </Typography>
              </>
            ) : (
              <Typography>{NotSpecified}</Typography>
            )}
          </Box>
        </Grid>
        )}
        {who5Id && (
        <Grid item xs={4}>
          <Box display='flex' flexDirection='column'>
            <ScaleTitle
              scaleName='WHO-5'
              isLink={!!who5ScaleResult?.length}
              onScaleClickHandler={onScaleClickHandler}
            />
            {who5ScaleResult?.length ? (
              <>
                <Box display='flex'>
                  <Typography>{who5ScaleResult?.[0]?.score}</Typography>
                  {who5ScaleResult
                    && who5ScaleResult.length > 1
                    && comparison(who5ScaleResult, true)}
                </Box>
                <Typography>{who5ScaleResult?.[0]?.description}</Typography>

                <Typography color={theme.custom.colors.lightTextSecondary}>
                  {`(${format(new Date(who5ScaleResult?.[0]?.attemptedAt || ''), 'MM/dd/yyyy')})`}
                </Typography>
              </>
            ) : (
              <Typography>{NotSpecified}</Typography>
            )}
          </Box>
        </Grid>
        )}
        <PatientScaleDetailsDialog
          selectedRow={
            {
              attemptId: selectedScale?.attemptId,
              categoryId: selectedScale?.categoryId,
              takenOn: new Date(selectedScale?.attemptedAt).toLocaleDateString(),
              score: `${selectedScale?.score}/${selectedScale?.totalScore}`,
              scale: selectedScale?.scale,
              patientName: `${capitalize(patientCtx?.name?.firstName ?? '')} ${capitalize(patientCtx?.name?.lastName ?? '')}`,
              patientDOB: googleDateToDateString(patientCtx?.dateOfBirth as GoogleDate),
              providerName: `${capitalize(patientCtx?.provider?.firstName ?? '')} ${capitalize(patientCtx?.provider?.lastName ?? '')}`,
            } as unknown as TableData
          }
          setSelectedRow={() => setSelectedScale({} as Scale)}
          showNotes
        />
      </Grid>
    </TableCell>
  );
}
