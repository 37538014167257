import { useAuth0 } from '@auth0/auth0-react';
import Spinner from 'components/Common/Spinner';
import { useHttp } from 'hooks/use-fetch';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { auth, mainNav } from 'utils/spaUrls';

export default function BillingAdminRoute({
  outlet,
  showRoute = true,
}: {
  outlet: JSX.Element;
  showRoute?: boolean;
}) {
  const { isAuthenticated, isLoading } = useAuth0();
  const { providerId, isBillingAdmin } = useHttp();
  const allowRoute = React.useMemo(() => {
    if (isBillingAdmin) {
      return showRoute;
    }

    return !showRoute;
  }, [isBillingAdmin, showRoute]);

  if (isLoading) {
    return <Spinner />;
  }
  else if (isAuthenticated && !providerId) {
    return <Spinner />;
  }
  else if (isAuthenticated && allowRoute) {
    return outlet;
  }
  else if (isAuthenticated) {
    return <Navigate to={mainNav.dashboard} />;
  }
  else {
    return <Navigate to={auth.login} />;
  }
}
