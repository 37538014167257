import { CacheKeysModel } from 'types/CacheKeysModel';

export const CacheKeys: CacheKeysModel = {
  enableDarkMode: 'enable-dark-mode',
  orgId: 'org-id',
  userId: 'user-id',
};

export const LogoUrl = {
  sm: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/favicon_w47qqz.ico',
  lg: 'https://res.cloudinary.com/diyfx2bz3/image/upload/v1706894684/headlamp-health-logo-light-bg_tsykhe.png',
};

export const NotSpecified = 'Not specified';

export const WelcomeExplorerActionId = 'welcome-action';

export enum MixpanelEventName {
  SEE_ANSWERS_CLICKED = 'See answers clicked',
  PATIENT_NAME_CLICKED = 'Patient name clicked',
  DOWNLOAD_PDF_CLICKED = 'Download pdf clicked',
  FILTERS_APPLIED = 'Filters applied',
  EXPORT_DATA_CLICKED = 'Export data clicked',
  SCALES_SUGGESTED = 'Scales suggested',
  SCALE_CLICKED = 'Scale clicked',
  ADDED_NOTE_ON_SCALE = 'Added note on scale',
  REPORTS_ROUTE_VISITED = 'Reports route visited',
}
