import { useAuth0 } from '@auth0/auth0-react';
import * as React from 'react';
import http, { IFetchParams, _fetch } from 'utils/http';
import * as Sentry from '@sentry/react';
import mixpanel from 'mixpanel-browser';
import { ProviderPractice } from 'pages/SuperAdmin/types';
import { UserMetadataFlags } from 'pages/Dashboard/types';
import { fetchUserMetadata } from 'pages/Dashboard/services/provider.services';

type FetchContextValues = {
  http: typeof http;
  providerId: string;
  updateActiveProvider: (providerId: ProviderPractice) => void;
  superAdminId: string;
  activeProvider: ProviderPractice;
  isSuggestScalesEnabled: boolean;
  isBillingAdmin: boolean;
};

export const FetchContext = React.createContext<FetchContextValues>({
  http: { ...http },
  providerId: '',
  updateActiveProvider: () => null,
  superAdminId: '',
  activeProvider: {} as ProviderPractice,
  isSuggestScalesEnabled: false,
  isBillingAdmin: false,
});

type FetchContextProviderProps = {
  children: React.ReactNode;
};

export function FetchContextProvider({ children }: FetchContextProviderProps) {
  const { getAccessTokenSilently, getIdTokenClaims, isAuthenticated } = useAuth0();
  const [providerId, setProviderId] = React.useState('');
  const [superAdminId, setSuperAdminId] = React.useState('');
  const [activeProvider, setActiveProvider] = React.useState({} as ProviderPractice);
  const [isSuggestScalesEnabled, setSuggestScalesEnabled] = React.useState(false);
  const [isBillingAdmin, setIsBillingAdmin] = React.useState(false);

  const fetchWithToken = React.useCallback(
    async (url: string, params: IFetchParams) => {
      const authToken = await getAccessTokenSilently();
      const headers = (params.headers as Headers) ?? {};
      return _fetch(url, {
        ...params,
        headers: {
          Authorization: `Bearer ${authToken}`,
          ...headers,
        } as Partial<RequestInit>,
      });
    },
    [getAccessTokenSilently],
  );

  const httpModule = React.useMemo(
    () => ({
      delete: async (url: string) => fetchWithToken(url, {
        method: 'DELETE',
      }),
      get: async (url: string) => fetchWithToken(url, {
        method: 'GET',
      }),
      patch: async (url: string, payload: object) => fetchWithToken(url, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),

      post: async (url: string, payload: object, params?: IFetchParams) => fetchWithToken(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        ...(params ?? {}),
      }),

      put: async (url: string, payload: object) => fetchWithToken(url, {
        method: 'PUT',
        body: JSON.stringify(payload),
      }),
    }),
    [fetchWithToken],
  );

  const updateActiveProvider = React.useCallback(
    (provider: ProviderPractice) => {
      setProviderId(provider.providerId);
      setActiveProvider(provider);
    },
    [setProviderId, setActiveProvider],
  );

  const getPatientManifestFeatureFlag = React.useCallback(async (userId: string) => {
    try {
      const patientScalesEnabledResponse = await fetchUserMetadata(httpModule.post, {
        name: UserMetadataFlags.SuggestScaleFeatureFlag,
        userId,
      })();
      setSuggestScalesEnabled(patientScalesEnabledResponse.flag?.value === 'true');
    }
    catch (err) {
      setSuggestScalesEnabled(false);
    }
  }, [httpModule.post]);


  const setProviderIdFromClaims = React.useCallback(async () => {
    const idTokenClaims = await getIdTokenClaims();
    setProviderId(idTokenClaims?.provider_id);
    getPatientManifestFeatureFlag(idTokenClaims?.provider_id);
    const billingAdminRole = idTokenClaims?.['headlamp/roles']?.includes('billingadmin');
    const hasSuperAdminRole = idTokenClaims?.['headlamp/roles']?.includes('superadmin');
    setIsBillingAdmin(billingAdminRole);
    setSuperAdminId(hasSuperAdminRole ? idTokenClaims?.provider_id : '');
    Sentry.setUser({ email: idTokenClaims?.email, id: idTokenClaims?.provider_id });
    mixpanel.identify(idTokenClaims?.provider_id);
    mixpanel.people.set({
      name: idTokenClaims?.name,
      email: idTokenClaims?.email,
      type: 'provider',
    });
  }, [getIdTokenClaims, getPatientManifestFeatureFlag]);

  React.useEffect(() => {
    if (isAuthenticated) {
      setProviderIdFromClaims();
    }
  }, [isAuthenticated, setProviderIdFromClaims]);

  const ctxValue = React.useMemo(
    () => ({
      http: httpModule,
      providerId,
      activeProvider,
      superAdminId,
      isSuggestScalesEnabled,
      isBillingAdmin,
      updateActiveProvider,
    }),
    [
      httpModule,
      providerId,
      activeProvider,
      superAdminId,
      isSuggestScalesEnabled,
      isBillingAdmin,
      updateActiveProvider,
    ],
  );

  return <FetchContext.Provider value={ctxValue}>{children}</FetchContext.Provider>;
}

export function useHttp() {
  const values = React.useContext(FetchContext);
  if (!values) {
    throw Error('Component is not inside FetchContext Provider');
  }
  return { ...values };
}
